import { createSignal, createEffect } from 'solid-js';

const STORAGE_KEY = 'stateOfConsentDisclosure';

const extractIdFromPath = (path: string): string | null => {
  const match = path.match(/\/rental-applications\/(\d+)\/[\w-]+/);
  return match ? match[1] : null;
};

const getInitialConsent = (): { id: string; hasConsented: boolean } => {
  if (typeof window === 'undefined') return { id: '', hasConsented: false };

  const value = localStorage.getItem(STORAGE_KEY);
  if (value) {
    try {
      const obj: { id: string; hasConsented: boolean } = JSON.parse(value);
      return obj;
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
    }
  }
  return { id: '', hasConsented: false };
};

export const useConsentDisclosure = () => {
  const [userId, setUserId] = createSignal<string | null>(null);
  const [hasConsented, setHasConsented] = createSignal<boolean>(false);

  const updateStatus = () => {
    const storedConsent = getInitialConsent();
    const id = userId();

    if (!id) return;

    if (id === storedConsent.id) {
      setHasConsented(storedConsent.hasConsented);
    } else {
      const stateObj = { id, hasConsented: hasConsented() };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(stateObj));
    }
  };

  createEffect(() => {
    const id = userId();
    if (!id) {
      return;
    }
    const stateObj = { id, hasConsented: hasConsented() };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(stateObj));
  });

  const setPath = (path: string) => {
    const id = extractIdFromPath(path);
    console.log('setPath中获取id', id);
    setUserId(id);
  };

  return { hasConsented, setHasConsented, setPath, userId, updateStatus };
};
